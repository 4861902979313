<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center">
      <h2>{{ $t('activity_banner.head_add') }}</h2>
      <v-divider class="mx-3" vertical />
      <v-breadcrumbs :items="breadcrumbsItems" divider=">>" />
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title>{{ $t('activity_banner.head_add_list') }} </v-card-title>

        <v-form ref="form" v-model="formValid" @submit.prevent="submitForm">
          <v-divider class="mx-3" />
          <!-- Title Input -->
          <v-card-actions>
            <v-row class="d-flex flex-column">
              <v-row class="ma-0">
                <v-col cols="12" xs="3" md="3" xl="6">
                  <v-text-field v-model="formData.name" :label="$t('activity_banner.header2')" outlined
                    dense></v-text-field>
                </v-col>
                <v-col cols="12" xs="6" md="6" xl="6">
                  <v-text-field v-model="formData.detail" :label="$t('activity_banner.header3')" outlined
                    dense></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col cols="12" xs="9" md="9" xl="9">
                  <v-text-field v-model="formData.moreDetail" :label="$t('activity_banner.header4')" outlined
                    dense></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col cols="12" xs="9" md="9" xl="9">
                  <v-text-field v-model="formData.image" :label="$t('activity_banner.header5')" outlined
                    dense></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col cols="12" xs="9" md="9" xl="9">
                  <v-text-field v-model="formData.link" :label="$t('activity_banner.header6')" outlined
                    dense></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col cols="12" xs="3" md="3" xl="6">
                  <v-text-field v-model="formData.order" :rules="formRule.order" :label="$t('activity_banner.header7')"
                    outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" xs="3" md="3" xl="6">
                  <v-select v-model="formData.enable" :items="statusTypes" item-text="statusTypes" item-value="value"
                    :label="$t('activity_banner.header8')" outlined dense></v-select>
                </v-col>
              </v-row>
            </v-row>
          </v-card-actions>


          <v-card-actions>
            <v-spacer />
            <v-btn color="warning" @click="$router.push({ name: 'activitybanner' })">
              <v-icon>mdi-arrow-left</v-icon>
              <span class="px-3">{{ $t('back') }}</span>
            </v-btn>
            <v-btn color="success" class="px-3" @click="submitForm" :loading="loading">
              <v-icon small>mdi-square-edit-outline</v-icon>
              <span color="primary" class="px-3">{{ $t('activity_banner.create') }}</span></v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import StaffLimitAccessPage from '@/components/staff/StaffLimitAccessPage.vue'
import store from '@/store'
import moment from 'moment'

export default {
  components: {
    StaffLimitAccessPage,
  },
  data() {
    return {
      loading: false,
      formValid: true,
      formRule: {
        order: [value => /^\d+$/.test(value) || this.$t('activity_banner.alert2')],
      },
      formData: {
        name: '',
        detail: '',
        moreDetail: '',
        image: '',
        link: '',
        order: 0,
        enable: 'เปิด',
      },
      statusTypes: ['เปิด', 'ปิด'],

      breadcrumbsItems: [
        {
          text: this.$t('activity_banner.head'),
          disabled: false,
          href: '/activitybanner',
        },
        {
          text: this.$t('activity_banner.head_add'),
          disabled: true,
          href: '',
        },
      ],

      acceptItems: [
        {
          text: this.$t('yes'),
          value: 'yes',
        },
        {
          text: this.$t('not'),
          value: 'no',
        },
      ],
      roles: [],
    }
  },
  async created() {
    this.addLogPage()
    try {
      this.roles = await this.$store.dispatch('getRoleData')
    } catch (e) { }

  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch('https://jsonip.com/').then(res =>
          res.json().then(
            el =>
            (data = {
              ip: el.ip,
              name: 'Activity Banner',
              url: window.location.href,
              detail: 'สร้าง Activity banner',
              admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
            }),
          ),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    submitForm() {
      if (this.formData.name == "") {
        this.$swal
          .fire({
            icon: 'warning',
            title: this.$t('alert'),
            text: this.$t('activity_banner.alert3'),
            showCancelButton: true,
            confirmButtonText: this.$t('confirm'),
          })
        return;
      }
      if (this.$refs.form.validate()) {
        this.$swal
          .fire({
            icon: 'warning',
            title: this.$t('alert'),
            text: this.$t('activity_banner.alert_create'),
            showCancelButton: true,
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('cancel'),
          })
          .then(async action => {
            if (action.isConfirmed) {
              await this.addActivityBanner()
            }
          })
      }
    },
    async addActivityBanner() {
      // format to int
      let sendData = {
        "head_text": this.formData.name,
        "description_text": this.formData.detail,
        "extra_description_text": this.formData.moreDetail,
        "image": this.formData.image,
        "link": this.formData.link,
        "order": parseInt(this.formData.order),
        "enable": (this.formData.enable == 'เปิด') ? true : false,
      }


      this.loading = true
      try {
        let res = await this.$store.dispatch('addActivityBanner', sendData)
        console.log(res.id);
        if (res.id) {
          this.$swal.fire(`${this.$t('activity_banner.create_success1')} ${this.formData.name} ${this.$t('activity_banner.create_success2')}`, '', 'success').then(action => {
            this.$router.push({ name: 'activitybanner' })
          })
        } else if (res.code === 400) {
          this.$swal.fire(
            `${this.$t('activity_banner.create_fail1_1')} ${this.formData.title} ${this.$t('activity_banner.create_fail1_2')}`,
            '',
            'error',
          )
        } else {
          this.$swal.fire(this.$t('activity_banner.create_fail2'), '', 'error')
        }
        // this.$refs.form.reset()
      } catch (e) {
        if (
          !e.message.includes('Unexpected token') ||
          !e?.includes('Unexpected token') ||
          !e.message.includes('Unexpected identifier')
        ) {
          this.$swal.fire({
            icon: 'error',
            title: e,
          })
        }
      }

      this.loading = false
    },
  },
  computed: {
  },
  watch: {
  },
}
</script>
